<template>
  <div>
    <v-row dense class="fs-12">
      <v-col
        cols="8"
        class="fs-16 font-weight-medium primary--text d-flex align-center"
      >
        {{
          order.dc_distributor_name ||
          `Kho: ${order.warehouse_destination_code}`
        }}
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn
          small
          color="success"
          @click="handoverFinish"
          :disabled="!isAllChecked"
        >
          {{ $t("labels.finish") }}
        </v-btn>
      </v-col>
      <v-col cols="6">
        {{ $t("labels.sku") }}:
        {{ order.total_sku }}
      </v-col>
      <v-col cols="6" class="text-right">
        {{ $t("labels.quantity_1") }}:
        {{ order.total_item }}
      </v-col>
      <v-col cols="12">
        <input-qr-scan-model
          v-model="code"
          ref="codeRef"
          :label="$t('labels.uid')"
          @keyupEnter="scanInput"
        ></input-qr-scan-model>
      </v-col>
    </v-row>

    <div class="mt-5">
      <v-simple-table
        fixed-header
        class="table-padding-2"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.goods") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 75px">
                {{ $t("labels.unit") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 75px">
                {{ $t("labels.request_quantity") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 75px">
                {{ $t("labels.scanned") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="(item, index) in items"
              :key="`${index}_${item.id_dc_goods}`"
            >
              <td>
                <div class="fs-14 font-weight-medium">
                  {{ item.name }}
                </div>
                <div>
                  <span class="error--text">
                    {{ item.customer_goods_barcode }}
                  </span>
                </div>
              </td>
              <td>{{ item.unit }}</td>
              <td>
                <span class="fs-18 font-weight-medium">
                  {{ formatNumber(item.quantity) }}
                </span>
              </td>
              <td>
                <template v-if="item.is_present">
                  <v-btn
                    v-if="!item.is_handed"
                    x-small
                    color="warning"
                    @click="itemHanded(item, index)"
                  >
                    {{ $t("labels.done_1") }}
                  </v-btn>
                  <v-icon v-else color="success"> mdi-check-circle </v-icon>
                </template>
                <template v-else>
                  <span
                    class="fs-18 font-weight-medium"
                    :class="{
                      'error--text': item.scanned.length != item.quantity,
                    }"
                  >
                    {{ formatNumber(item.scanned.length) }}
                  </span>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce, formatDateTime, formatNumber } from "@/libs/helpers";

export default {
  name: "HandoverProcessing",
  components: {},
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    isLoading: false,
    code: null,
  }),
  computed: {
    isAllChecked() {
      const uncheck = [...this.items].filter(
        (item) => item.quantity != item.scanned.length && !item.is_handed
      );
      return uncheck.length === 0;
    },
    scannedCodes() {
      const codes = [];
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        for (let s = 0; s < item.scanned.length; s++) {
          const iScanned = item.scanned[s];
          codes.push(iScanned.code.toLowerCase());
        }
      }
      return codes;
    },
  },
  mounted() {
    this.getItems();
  },
  methods: {
    formatDateTime,
    formatNumber,
    getItems: debounce(function () {
      httpClient.post("/dc/v1/get-order-items", this.order).then(({ data }) => {
        this.items = [...data].map((item) => ({
          ...item,
          scanned: [],
          is_handed: false,
        }));
      });
    }, 500),
    itemHanded(item, index) {
      const items = [...this.items];
      items[index].is_handed = true;
      this.items = [...items];
    },
    async scanInput() {
      if (!this.code) {
        return false;
      }

      let code = this.code.toLowerCase();

      if (code.includes("/")) {
        const codeSpl = code.split("/");
        code = codeSpl[codeSpl.length - 1];
      }

      if (this.scannedCodes.includes(code)) {
        this.code = null;
        this.$vToastify.error("Thùng đã quét");
        document.getElementById("DcThungDaQuet_player").play();
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(`/dc/v1/get-box-by-code`, {
          code,
        });
        this.isLoading = false;
        this.code = null;

        const item = [...this.items].find(
          (item) => item.id_dc_goods == data.id_dc_goods
        );

        if (!item) {
          this.$vToastify.error("Nhầm sản phẩm");
          document.getElementById("DcNhamSanPham_player").play();
          return false;
        }

        const index = [...this.items].findIndex(
          (item) => item.id_dc_goods == data.id_dc_goods
        );
        const items = [...this.items];

        if (items[index].scanned.length >= items[index].quantity) {
          this.$vToastify.error("Thừa hàng rồi");
          document.getElementById("DcThuaHangRoi_player").play();
          return false;
        }

        items[index].scanned.push(data);
        this.items = [...items];

        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.isLoading = false;
        this.code = null;
        /* const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null; */
        this.$vToastify.error("Mã thùng không tồn tại");
        document.getElementById("DcMaThungKhongTonTai_player").play();
      }
    },
    async handoverFinish() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        const { data } = await httpClient.post(`/dc/v1/handover-finish`, {
          id: this.order.id,
          items: this.items,
        });
        this.isLoading = false;
        this.$emit("onFinished", data);
        this.$vToastify.success("Bàn giao thành công");
        document.getElementById("success_sound_player").play();
      } catch (e) {
        document.getElementById("error_sound_player").play();
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>
